import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Chip } from '@mui/material';

export default function ProjectName(props) {
    const [users, setUsers] = useState([]);
    const [Nsearch, setNSearch] = useState("");

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`https://ideawork.in/api/projects/name/${props.id}`);
                setUsers(response.data);

                if (response.data.length > 0) {
                    setNSearch(response.data[0].name); // Setting Nsearch with the first user's _id
                }
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        fetchUsers();
    }, [props.id]);

    // Conditionally render the Nsearch value if it is set
    return (
        <>
            {Nsearch && (
                
                  <Chip label={Nsearch}/>
                    
               
            )}
        </>
    );
}

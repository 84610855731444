import React, { useState, useEffect } from 'react';
import AnalyticsDashboard from '../components/AnalyticsDashboard';

function Analytics() {
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [equipments, setEquipments] = useState([]);

  useEffect(() => {
    // In a real application, you would fetch this data from your API
    // For now, we'll use some dummy data
    setProjects([
      { id: 1, name: 'Project A', status: 'In Progress', startDate: '2023-01-15', endDate: '2023-06-30' },
      { id: 2, name: 'Project B', status: 'Completed', startDate: '2023-02-01', endDate: '2023-05-15' },
      { id: 3, name: 'Project C', status: 'Not Started', startDate: '2023-07-01', endDate: '2023-12-31' },
    ]);
    setTasks([
      { id: 1, name: 'Task 1', status: 'In Progress', dueDate: '2023-03-15' },
      { id: 2, name: 'Task 2', status: 'Not Started', dueDate: '2023-04-01' },
      { id: 3, name: 'Task 3', status: 'Completed', dueDate: '2023-02-28' },
      { id: 4, name: 'Task 4', status: 'In Progress', dueDate: '2023-05-15' },
    ]);
    setEquipments([
      { id: 1, name: 'Equipment A', status: 'Operational' },
      { id: 2, name: 'Equipment B', status: 'Under Maintenance' },
      { id: 3, name: 'Equipment C', status: 'Operational' },
      { id: 4, name: 'Equipment D', status: 'Out of Order' },
    ]);
  }, []);

  return (
    <AnalyticsDashboard 
      projects={projects}
      tasks={tasks}
      equipments={equipments}
    />
  );
}

export default Analytics;
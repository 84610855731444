import React from 'react';
import { Typography, Box, Paper, Chip } from '@mui/material';

function EquipmentDetails({ equipment }) {
  if (!equipment) return null;

  return (
    <Paper sx={{ p: 2, mt: 2 }}>
      <Typography variant="h5" gutterBottom>{equipment.name}</Typography>
      <Box sx={{ mb: 2 }}>
        <Chip 
          label={equipment.status} 
          color={equipment.status === 'Operational' ? 'success' : equipment.status === 'Under Maintenance' ? 'warning' : 'error'} 
        />
      </Box>
      <Typography variant="body1"><strong>Type:</strong> {equipment.type}</Typography>
      <Typography variant="body1"><strong>Purchase Date:</strong> {equipment.purchaseDate}</Typography>
      <Typography variant="body1"><strong>Last Maintenance:</strong> {equipment.lastMaintenanceDate}</Typography>
      <Typography variant="body1"><strong>Next Maintenance:</strong> {equipment.nextMaintenanceDate}</Typography>
      <Typography variant="body1" paragraph><strong>Notes:</strong> {equipment.notes}</Typography>
    </Paper>
  );
}

export default EquipmentDetails;
import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import { Typography, Button } from '@mui/material';
import AdminReqDetail from './AdminReqDetail';
import axios from 'axios';

export default function AdminReq() {
    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedId, setSelectedId] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get('https://ideawork.in/api/requests/adminlist');
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        fetchUsers();
    }, [selectedId]);

    const handleClickOpen = (id) => {
        setSelectedId(id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedId(null); // Reset selected ID when modal is closed
    };

    return (
        <>
            {users.map((data) =>
                <Alert style={{ marginTop: '5px' }} icon={data.appstatus === 2 ? (<WavingHandIcon fontSize="inherit" />):<FiberNewIcon fontSize="inherit" />} severity={data.appstatus === 2 ? "warning":'error'}
                    action={
                        <Button color="inherit" id={data._id} size="small" onClick={() => handleClickOpen(data._id)}>
                            <VisibilityIcon />
                        </Button>
                    }
                    key={data._id} // Adding a key here for unique identification
                >
                    <Typography><strong>{data.subject}</strong> ({data.cdate})</Typography>
                </Alert>
            )}
            {open && (
                <AdminReqDetail open={open} onClose={handleClose} id={selectedId} />
            )}
        </>
    );
}

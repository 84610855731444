import React from 'react';
import { ListItem, ListItemText, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useNotifications } from '../contexts/NotificationContext';

function NotificationItem({ notification }) {
  const { removeNotification } = useNotifications();

  return (
    <ListItem>
      <ListItemText 
        primary={notification.title}
        secondary={notification.message}
      />
      <IconButton edge="end" aria-label="delete" onClick={() => removeNotification(notification.id)}>
        <CloseIcon />
      </IconButton>
    </ListItem>
  );
}

export default NotificationItem;
import React, { useState,useEffect } from 'react';
import { Typography, Box, Paper, Chip } from '@mui/material';
import axios from 'axios';
import SingleUser from './SingleUser';
import Stack from '@mui/material/Stack';

function TaskDetails({ task }) {
  
  // const [users, setUsers] = useState([]);
  // const finus = [];
  // useEffect(() => {
  //   const fetchUsers = async (hello) => {
      
  //     try {
  //       // const response = await axios.get('https://ideawork.in/api/users/single/66aa3dc0e011abbc158494e3');
  //       const response = await axios.get(`https://ideawork.in/api/users/single/${hello}`);
        
  //       setUsers(response.data)
  //       // finus.push(response.data)
  //       // //console.log(response.data)
  //       return(response.data.username)
        
        
                  
  //     } catch (error) {
  //       console.error('Error fetching users:', error);
  //     }finally {
  //       // //console.log('333333333333333333333333')
  //       // (users.selectedUser).forEach( (data) => (//console.log(data)))
  //       // //console.log(users[0].username + 'ji')
  //       // setUsers(finus)
        
  //     }
  //   };
  //   // fetchUsers()
  //   (task.selectedUser).map( (data) => {
  //    fetchUsers(data)
  //     // //console.log( 'seke'+ data)
  //     // //console.log( 'seke'+ fc)
  // })
    
    
  // }, []);
 
  if (!task) return null;
  return (
    <Paper sx={{ p: 2, mt: 2 }}  >
      <Typography variant="h5" gutterBottom>{task.title}</Typography>
      <Box sx={{ mb: 2 }}>
        <Stack direction="row" gap={1}>
          <Chip label={task.status} color={task.status === 'Completed' ? 'success' : 'default'} />
          <Chip label={task.priority} color={task.priority === 'High' ? 'error' : task.priority === 'Medium' ? 'warning' : 'default'} />
          <Chip label={task.typeTask === 'None' ? 'Parent' : task.typeTask} color="primary" />
        </Stack>
      </Box>
      <Typography variant="body1" paragraph><strong>Description:</strong> {task.description}</Typography>
      <Typography variant="body1"><strong>Due Date:</strong> {task.dueDate}</Typography>
      <Typography variant="body1"><strong>Assigned To:</strong> 
      <Stack gap={1} direction='row'>
      {(task.selectedUser).map( data => (
        <SingleUser user={data} type="" />
      ))}
      </Stack>
      </Typography>
    </Paper>
  );
}

export default TaskDetails;
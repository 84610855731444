import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Add Link here
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import Alert from '@mui/material/Alert';
import axios from 'axios';
// import socket from '../utils/socket';
import Snackbar from '@mui/material/Snackbar';


function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const [open, setOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };
  
  const handleLogin = async (e) => {
    e.preventDefault();
   

    try {
      const response = await axios.get(`https://ideawork.in/api/users/${formData.email},${formData.password}`);
      
      if (response.data.email === formData.email && response.data.password === formData.password) {
        const email = response.data.email
        const role = response.data.role
        const id = response.data._id
        const username = response.data.username
        
        setOpen(true)
        setTimeout(() => {
          if (role === 0) {
            navigate('/projects');
          } else {
            navigate('/');
          }
        }, 2000);
        login({ email, role,id,username });

      }

    } catch (error) {
      console.error('Error:', error);

      
      setError(<Alert severity="error">Credentials Are Incorrect !</Alert>)
      setTimeout(() => {
        setError("")
        
      }, 3000);

    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in to Hill Point
        </Typography>
        <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Email"
            name="email"
            autoComplete="username"
            autoFocus
            value={formData.email}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formData.password}
            onChange={handleChange}
          />
          <Snackbar open={open}>
            <Alert

              severity="success"
              variant="filled"
              sx={{ width: '100%' }}
            >
              Login successfully!
            </Alert>
          </Snackbar>
          {error && <Typography color="error">{error}</Typography>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Link to="/register" style={{ textDecoration: 'none' }}>
            <Typography variant="body2" color="primary" align="center">
              Don't have an account? Register here
            </Typography>
          </Link>
        </Box>
      </Box>
    </Container>
  );
}

export default Login;
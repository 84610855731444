import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, MenuItem } from '@mui/material';

const statuses = ['Operational', 'Under Maintenance', 'Out of Order'];

function EquipmentForm({ onSubmit, initialData }) {
  const [equipment, setEquipment] = useState({
    name: '',
    type: '',
    status: 'Operational',
    purchaseDate: '',
    lastMaintenanceDate: '',
    nextMaintenanceDate: '',
    notes: ''
  });

  useEffect(() => {
    if (initialData) {
      setEquipment(initialData);
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEquipment(prevEquipment => ({ ...prevEquipment, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(equipment);
    setEquipment({
      name: '',
      type: '',
      status: 'Operational',
      purchaseDate: '',
      lastMaintenanceDate: '',
      nextMaintenanceDate: '',
      notes: ''
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <TextField
        fullWidth
        label="Equipment Name"
        name="name"
        value={equipment.name}
        onChange={handleChange}
        margin="normal"
        required
      />
      <TextField
        fullWidth
        label="Equipment Type"
        name="type"
        value={equipment.type}
        onChange={handleChange}
        margin="normal"
        required
      />
      <TextField
        select
        fullWidth
        label="Status"
        name="status"
        value={equipment.status}
        onChange={handleChange}
        margin="normal"
      >
        {statuses.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        label="Purchase Date"
        name="purchaseDate"
        type="date"
        value={equipment.purchaseDate}
        onChange={handleChange}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        fullWidth
        label="Last Maintenance Date"
        name="lastMaintenanceDate"
        type="date"
        value={equipment.lastMaintenanceDate}
        onChange={handleChange}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        fullWidth
        label="Next Maintenance Date"
        name="nextMaintenanceDate"
        type="date"
        value={equipment.nextMaintenanceDate}
        onChange={handleChange}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        fullWidth
        label="Notes"
        name="notes"
        value={equipment.notes}
        onChange={handleChange}
        margin="normal"
        multiline
        rows={4}
      />
      <Button type="submit" variant="contained" sx={{ mt: 3 }}>
        {initialData ? 'Update Equipment' : 'Add Equipment'}
      </Button>
    </Box>
  );
}

export default EquipmentForm;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import RegisterForm from '../components/RegisterForm';


function Register() {
  const navigate = useNavigate();
  const { register } = useAuth();

  const handleRegister = (userData) => {
    register(userData);
    navigate('/');
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Register for Hill Point
        </Typography>
        <RegisterForm onRegister={handleRegister} />
      </Box>
    </Container>
  );
}

export default Register;
import React, { useState, useEffect } from 'react';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Box, Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Badge, Menu, MenuItem, Hidden } from '@mui/material';
import { Dashboard as DashboardIcon, Assignment as ProjectIcon, Task as TaskIcon, Build as EquipmentIcon, Description as DocumentIcon, BarChart as AnalyticsIcon, Menu as MenuIcon, Notifications as NotificationsIcon } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNotifications } from '../contexts/NotificationContext';
import NotificationItem from './NotificationItem';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const drawerWidth = 240;

function Layout() {
  const [role, setRole] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { notifications, clearAllNotifications } = useNotifications();
  const [anchorEl, setAnchorEl] = useState(null);

  
  // Fetch the role from sessionStorage when the component mounts
  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    if (user) {
      setRole(user.role);
    }else {
      handleLogout()
    }
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('user'); // Clear session storage
    setRole(null); // Reset role state
    navigate('/login');
  };

  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    
    { text: 'Projects', icon: <ProjectIcon />, path: '/projects' },
    { text: 'Tasks', icon: <TaskIcon />, path: '/tasks' },
    { text: 'Equipment', icon: <EquipmentIcon />, path: '/equipment' },
    { text: 'Documents', icon: <DocumentIcon />, path: '/documents' },
    { text: 'Analytics', icon: <AnalyticsIcon />, path: '/analytics' },
    { text: 'Requests', icon: <CheckCircleIcon />, path: '/requests' },
  ];

  // Conditionally add the 'Users' menu item based on role
  if (role === 1 || role === 2) {
    menuItems.push(
      { text: 'Users', icon: <AccountCircleIcon />, path: '/users' },
    );
    menuItems.unshift({ text: 'Dashboard', icon: <DashboardIcon />, path: '/' },)
  }

  const drawer = (
    <div>
      <Toolbar />
      <List>
        {menuItems.map((item) => (
          <ListItem 
            button 
            key={item.text} 
            component={Link} 
            to={item.path}
            selected={location.pathname === item.path}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
        <br />
        <Hidden smUp>
          <Button color="error" style={{ display: 'block', width: '80%', margin: 'auto' }} variant="outlined" onClick={handleLogout}>Logout</Button>
        </Hidden>
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }} key={role}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <span><img width={200} align='center' src='Logo.png' alt="Logo" /></span> 
          </Typography>
          <Hidden smDown>
            <Typography style={{ marginRight: '80px' }}>
              <div style={{ fontSize: '15px' }}>
                R&D Connect
              </div>
              <div style={{ fontSize: '8px' }}>
                powered by Tachus Technology
              </div>
            </Typography>
          </Hidden>
          <IconButton color="inherit" onClick={handleNotificationClick}>
            <Badge badgeContent={notifications.length} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleNotificationClose}
          >
            {notifications.length > 0 ? (
              <>
                <List>
                  {notifications.map(notification => (
                    <NotificationItem key={notification.id} notification={notification} />
                  ))}
                </List>
                <MenuItem onClick={clearAllNotifications}>Clear All</MenuItem>
              </>
            ) : (
              <MenuItem>No new notifications</MenuItem>
            )}
          </Menu>
          <Hidden smDown>
            <Button color="inherit" onClick={handleLogout}>Logout</Button>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

export default Layout;

import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, MenuItem } from '@mui/material';
import Userlist from './Userlist';
import Stack from '@mui/material/Stack';

function ProjectForm({ onSubmit, initialData }) {

  const [selectedItems, setSelectedItems] = useState({});

  const handleSelectionChange = (name, checked) => {
    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      [name]: checked,
    }));
    // Object.assign(selectedItems,initialData.selectedUser)
    // console.log(selectedItems)
  };
  

  const [project, setProject] = useState({
    name: '',
    description: '',
    status: 'Not Started',
    startDate: new Date().toISOString().split('T')[0],
    endDate: '',
    budget: '',
    resources: '',
  });

  const checkedUsers = Object.keys(selectedItems).filter((item) => selectedItems[item]);
  useEffect(() => {
    
    // setProject({ ...initialData, _id: initialData._id || undefined,selectedItems });
    if (initialData) {

      // Ensure we're not overwriting the _id if it exists
      setProject({ ...initialData, _id: initialData._id || undefined });

      const initialSelectedItems = {};
      initialData.selectedUser.forEach(id => {
        initialSelectedItems[id] = true;
      });
      setSelectedItems(initialSelectedItems);

    }
  }, [initialData]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProject(prevProject => ({ ...prevProject, [name]: value }));
    //console.log(project)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkedUsers.length === 0) {
      
      onSubmit(project);
    }else {
      project.selectedUser = checkedUsers;
      onSubmit(project);
    }
    
    //console.log(checkedUsers)
    // const finalUser = JSON.stringify(selectedItems, null, 2)
    // const final = {
    //   selectedItems:[e.target.selectedItems.value],
    //   name:e.target.elements.name.value,
    // }
    //console.log(project)
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <TextField
        fullWidth
        label="Project Name"
        name="name"
        value={project.name}
        onChange={handleChange}
        margin="normal"
        required
      />
     
      <TextField
        fullWidth
        label="Description"
        name="description"
        value={project.description}
        onChange={handleChange}
        margin="normal"
        multiline
        rows={4}
      />
      
      <TextField
        select
        fullWidth
        label="Status"
        name="status"
        value={project.status}
        onChange={handleChange}
        margin="normal"
      >
        <MenuItem value="Not Started">Not Started</MenuItem>
        <MenuItem value="In Progress">In Progress</MenuItem>
        <MenuItem value="Completed">Completed</MenuItem>
      </TextField>
      <TextField
        fullWidth
        label="Start Date"
        name="startDate"
        type="date"
        value={project.startDate}
        onChange={handleChange}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        fullWidth
        label="End Date"
        name="endDate"
        type="date"
        value={project.endDate}
        onChange={handleChange}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        fullWidth
        label="Budget (₹)"
        name="budget"
        type="number"
        value={project.budget}
        onChange={handleChange}
        margin="normal"
        InputProps={{
          startAdornment: <span>₹</span>,
        }}
      />
      <TextField
        fullWidth
        label="Resources"
        name="resources"
        value={project.resources}
        onChange={handleChange}
        margin="normal"
        multiline
        rows={2}
        helperText="Enter resources separated by commas"
      />
      <Stack>
        <Userlist selectedItems={selectedItems} onSelectionChange={handleSelectionChange}/>
      </Stack>
      {/* <div>
        <h2>Selected Items:</h2>
        <pre>{}</pre>
      </div> */}
      <Button type="submit" variant="contained" sx={{ mt: 3 }}>
        {initialData ? 'Update Project' : 'Create Project'}
      </Button>
    </Box>
  );
}

export default ProjectForm;
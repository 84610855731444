import React from 'react';
import { BrowserRouter as Router, Route, Routes,  } from 'react-router-dom';
// import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { NotificationProvider } from './contexts/NotificationContext';
// import theme from './theme';

// Import pages
import Login from './pages/Login';
import Register from './pages/Register';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import Projects from './pages/Projects';
import Tasks from './pages/Tasks';
import Equipment from './pages/Equipment';
import Documents from './pages/Documents';
import Analytics from './pages/Analytics';
import UserProfile from './components/UserProfile';
import Userlist from './components/Userlist';
import Users from './pages/Users';
import Requests from './pages/Requests';
import RequestForm from './components/RequestForm';

// const PrivateRoute = ({ children }) => {
//   let role1 = JSON.parse(sessionStorage.getItem('user'));
//   const role = role1.role;
  
//   return user ? children : <Navigate to={window.location.pathname} />;

// };

function App() {
  return (
    <AuthProvider>
      <NotificationProvider>
          <CssBaseline />
          <Router>
            <Routes>
              <Route path="*" element={<Login />} /> 
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/" element={<Layout />}>
                <Route index element={<Dashboard />} />
                <Route path="projects" element={<Projects />} />
                <Route path="tasks" element={<Tasks />} />
                <Route path="equipment" element={<Equipment />} />
                <Route path="documents" element={<Documents />} />
                <Route path="analytics" element={<Analytics />} />
                <Route path="profile" element={<UserProfile />} />
                <Route path="users" element={<Users/>} />
                <Route path="requests" element={<RequestForm/>} />
                {/* <Route path="users" element={<Userlist/>} /> */}
              </Route>
            </Routes>
          </Router>
      </NotificationProvider>
    </AuthProvider>
  );
}

export default App;
// import React,{Navigate} from 'react';
import { Grid, Paper, Typography, List, ListItem, ListItemText, Chip, Box } from '@mui/material';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import AdminReq from './AdminReq';
import Documents from '../pages/Documents';
import { Navigate } from 'react-router-dom';

const COLORS = ['#0088FE', '#ed6c02', '#2e7d32', '#FF8042'];
let role = 1;
const DashboardCard = ({ title, children }) => (
  <Grid item xs={12} md={6} lg={6}>
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 280 }}>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {title}
      </Typography>
      {children}
    </Paper>
  </Grid>
);

const Dashboard = ({ projects, tasks, projectname, documents }) => {

  const role1 = JSON.parse(sessionStorage.getItem('user'));
  const role = 1
  
  // if (role1) {
  //   role = 1;
  // }
  // if (role1 == null) {
    
  //   return <Navigate to="/login" />;
  // }
  const projectStatus = [
    { name: 'Not Started', value:projects.filter(p => p.status === 'Not Started').length},
    { name: 'In Progress', value:projects.filter(p => p.status === 'In Progress').length },
    { name: 'Completed', value: projects.filter(p => p.status === 'Completed').length },
  ];

  const taskPriority = [
    { name: 'Completed', value: tasks.filter(t => t.status === 'Completed').length },
    { name: 'In Progress', value: tasks.filter(t => t.status === 'In Progress').length },
    { name: 'Not Started', value: tasks.filter(t => t.status === 'Not Started').length },
  ];

  return (
  <>
  
   {role1 ? role === 1 || role === 2:''  ? 
     <Typography variant='h4'>
     Admin Access
   </Typography> :'' 
  }
  {
    role == 0 ?'':<Typography variant='h4'>Admin Access</Typography>
  }
  { !role1 ? <Navigate to="/login"/>:''}
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={3} >
        <DashboardCard title="Project Status" width="500px" >
        
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={projectStatus}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={60}
                fill="#8884d8"
                dataKey="value"
                // label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
              >
                {projectStatus.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
                 
              </Pie>
             
              <Tooltip />
              
            </PieChart>
          </ResponsiveContainer>
          <div style={{display:'flex',gap:'2px',flexWrap:'wrap'}}>
            <Chip size="small" color='info'  label="Not Started"/>
            <Chip size="small" color='warning' label="In Progress"/>
            <Chip size="small" color='success' label="Completed"/>
          </div>
          
        </DashboardCard>

        {role == 0 ? '' :
        <>
          <DashboardCard title="Recent Requests">
            <div style={{maxheight:'260px',overflowY:'auto',margin:'auto'}}>
              <AdminReq/>
            </div>
        </DashboardCard>
        <DashboardCard title="Recent Projects">
          <List>
            {projectname.slice(0, 5).map((project) => (
              <ListItem key={project.id}>
                <ListItemText 
                  // primary={project.name} 
                  secondary={
                    <Chip 
                      label={project.name} 
                      size="small" 
                      color={
                        project.status === 'Completed' ? 'success' : 
                        project.status === 'In Progress' ? 'warning' : 'default'
                      } 
                    />
                  } 
                />
              </ListItem>
            ))}
          </List>
        </DashboardCard>
        </> }

        {/* <DashboardCard title="Recent Requests">
            <div style={{maxheight:'260px',overflowY:'auto'}}>
              <AdminReq/>
            </div>
        </DashboardCard>
        <DashboardCard title="Recent Projects">
          <List>
            {projects.slice(0, 5).map((project) => (
              <ListItem key={project.id}>
                <ListItemText 
                  // primary={project.name} 
                  secondary={
                    <Chip 
                      label={project.name} 
                      size="small" 
                      color={
                        project.status === 'Completed' ? 'success' : 
                        project.status === 'In Progress' ? 'warning' : 'default'
                      } 
                    />
                  } 
                />
              </ListItem>
            ))}
          </List>
        </DashboardCard> */}

        <DashboardCard title="Task Status">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={taskPriority}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </DashboardCard>

       

        <DashboardCard title="Upcoming Tasks">
        <div style={{maxheight:'260px',overflowY:'auto'}}>
          <List>
            {tasks.slice(0, 5).map((task) => (
              <ListItem key={task.id}>
                <ListItemText 
                  primary={task.title} 
                  secondary={`Due: ${task.dueDate}`} 
                />
                <Chip 
                  label={task.priority} 
                  size="small" 
                  color={
                    task.priority === 'High' ? 'error' : 
                    task.priority === 'Medium' ? 'warning' : 'default'
                  } 
                />
              </ListItem>
            ))}
          </List>
          </div>
        </DashboardCard>

        {/* <DashboardCard title="Equipment Status">
          <List>
            {equipment.slice(0, 5).map((item) => (
              <ListItem key={item.id}>
                <ListItemText 
                  primary={item.name} 
                  secondary={
                    <Chip 
                      label={item.status} 
                      size="small" 
                      color={item.status === 'Operational' ? 'success' : 'error'} 
                    />
                  } 
                />
              </ListItem>
            ))}
          </List>
        </DashboardCard> */}

        <DashboardCard title="Recent Documents">
          <div style={{maxheight:'260px',overflowY:'auto'}}>
            <Documents filterShow="dontshow"/>
          </div>
          {/* <List>
            {documents.slice(0, 5).map((doc) => (
              <ListItem key={doc.id}>
                <ListItemText primary={doc.title} secondary={doc.category} />
              </ListItem>
            ))}
          </List> */}
        </DashboardCard>
      </Grid>
    </Box>
    
    </>
  );
};

export default Dashboard;
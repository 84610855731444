import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';

function UserProfile({ user, onUpdateProfile }) {
  const [formData, setFormData] = useState({
    username: user.username,
    email: user.email,
    // Add more fields as needed
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdateProfile(formData);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom>User Profile</Typography>
      <TextField
        fullWidth
        label="Username"
        name="username"
        value={formData.username}
        onChange={handleChange}
        margin="normal"
        disabled
      />
      <TextField
        fullWidth
        label="Email"
        name="email"
        type="email"
        value={formData.email}
        onChange={handleChange}
        margin="normal"
        required
      />
      {/* Add more fields as needed */}
      <Button type="submit" variant="contained" sx={{ mt: 3 }}>
        Update Profile
      </Button>
    </Box>
  );
}

export default UserProfile;
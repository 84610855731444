import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Typography, Box, Button, Grid, Slide } from '@mui/material';
import ProjectList from '../components/ProjectList';
import ProjectForm from '../components/ProjectForm';
import ProjectDetails from '../components/ProjectDetails';
import CommunicationThread from '../components/CommunicationThread';
import socket from '../utils/socket';
import { useNotifications } from '../contexts/NotificationContext';

import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function Projects() {
  const [projects, setProjects] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editingProject, setEditingProject] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [messages, setMessages] = useState([]);
  // const [open, setOpen] = useState(false);
  const [mopen, setMOpen] = useState(false);
  // const handleClose = () => setOpen(false);
  const handleMClose = () => setMOpen(false);
  const { addNotification } = useNotifications();

  let role1 = JSON.parse(sessionStorage.getItem('user'));
  const role = role1.role;


  const fetchProjects = useCallback(async () => {
    try {
      if (role === 1 || role === 2 ){
        const response = await axios.get('https://ideawork.in/api/projects');
        setProjects(response.data);
        // console.log(response.data.length)
      }
      else {
        const response = await axios.get(`https://ideawork.in/api/projects/speci/${role1.id}`);
        setProjects(response.data);
      }
      
    } catch (error) {
      console.error('Error fetching projects:', error);
      addNotification({
        id: Date.now(),
        title: 'Error',
        message: 'Failed to fetch projects.'
      });
    }
  }, [addNotification]);

  useEffect(() => {
    fetchProjects();

    socket.on('projectAdded', (project) => {
      setProjects(prevProjects => [...prevProjects, project]);
      addNotification({
        id: Date.now(),
        title: 'New Project',
        message: `A new project "${project.name}" has been added.`
      });
    });

    socket.on('projectUpdated', (updatedProject) => {
      setProjects(prevProjects => 
        prevProjects.map(p => p._id === updatedProject._id ? updatedProject : p)
      );
      addNotification({
        id: Date.now(),
        title: 'Project Updated',
        message: `Project "${updatedProject.name}" has been updated.`
      });
    });

    socket.on('projectDeleted', (projectId) => {
      setProjects(prevProjects => prevProjects.filter(p => p._id !== projectId));
      addNotification({
        id: Date.now(),
        title: 'Project Deleted',
        message: 'A project has been deleted.'
      });
    });

    return () => {
      socket.off('projectAdded');
      socket.off('projectUpdated');
      socket.off('projectDeleted');
    };
  }, [fetchProjects, addNotification]);

  const handleAddProject = async (newProject) => {
    try {
      const response = await axios.post('https://ideawork.in/api/projects', newProject);
      setProjects(prevProjects => [...prevProjects, response.data]);
      setShowForm(false);
      socket.emit('addProject', response.data);
      addNotification({
        id: Date.now(),
        title: 'New Project',
        message: `A new project "${newProject._id}" has been added.`
      });
    } catch (error) {
      console.error('Error adding project:', error);
      addNotification({
        id: Date.now(),
        title: 'Error',
        message: 'Failed to add project.'
      });
    }
  };

  const handleEditProject = (projectId) => {
    const projectToEdit = projects.find(p => p._id === projectId);
    //console.log('Editing this'+ projectToEdit.selectedUser);
    //console.log(projectId + "this is id");
    setEditingProject(projectToEdit);
    setShowForm(true);
  };

  const handleUpdateProject = async (updatedProject) => {
    try {
      const response = await axios.put(`https://ideawork.in/api/projects/${updatedProject._id}`, updatedProject);
      setProjects(prevProjects => 
        prevProjects.map(p => p._id === updatedProject._id ? response.data : p)
      
      );
      setShowForm(false);
      setEditingProject(null);
      socket.emit('updateProject', response.data);
      addNotification({
        id: Date.now(),
        title: 'Project Updated',
        message: `Project "${updatedProject.name}" has been updated.`
      });
    } catch (error) {
      console.error('Error updating project:', error);
      addNotification({
        id: Date.now(),
        title: 'Error',
        message: 'Failed to update project.'
      });
    }
  };

  const handleDeleteProject = async (projectId) => {
    try {
      //console.log('Attempting to delete project with ID:', projectId);
      const response = await axios.delete(`https://ideawork.in/api/projects/${projectId}`);
      //console.log('Delete response:', response.data);

      if (response.data && response.data.message === 'Project deleted successfully') {
        setProjects(prevProjects => prevProjects.filter(p => p._id !== projectId));
        if (selectedProject && selectedProject._id === projectId) {
          setSelectedProject(null);
        }
        socket.emit('deleteProject', projectId);
        addNotification({
          id: Date.now(),
          title: 'Project Deleted',
          message: 'The project has been successfully deleted.'
        });
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Error deleting project:', error.response ? error.response.data : error.message);
      addNotification({
        id: Date.now(),
        title: 'Error',
        message: `Failed to delete project: ${error.response ? error.response.data.message : error.message}`
      });
    }
  };

  const handleViewProject = (projectId) => {
    const projectToView = projects.find(p => p._id === projectId);
    setSelectedProject(projectToView);
    //console.log(projectToView)
    //console.log(selectedProject)
    setMessages([]);
    // setOpen(true)
  };
  const handleViewMessage = (projectId) => {
    
    const projectToView = projects.find(p => p._id === projectId);
    setSelectedProject(projectToView);
    //console.log(projectToView)
    //console.log(selectedProject)
    setMessages([]);
    setMOpen(true)
  };

  const handleSendMessage = (newMessage) => {
    setMessages([...messages, { ...newMessage, sender: 'Current User' }]);
  };

  return (
    <>
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Projects
      </Typography>
      {role === 1 || role === 2 ? 
        <Button 
        variant="contained" 
        onClick={() => {
          setShowForm(!showForm);
          setEditingProject(null);
        }}
        sx={{ mb: 2 }}
      >
        {showForm ? 'Cancel' : 'Add New Project'}
      </Button> :'' 
       }
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          {showForm && (
            <ProjectForm 
              onSubmit={editingProject ? handleUpdateProject : handleAddProject} 
              initialData={editingProject}
            />
          )}
          {projects.length > 0 && showForm === false ? (
            <ProjectList 
              projects={projects} 
              onEditProject={handleEditProject}
              onDeleteProject={handleDeleteProject}
              onViewProject={handleViewProject}
              onViewMessage={handleViewMessage}
            />
          ) : showForm === false ? (
            <Typography>No projects yet. Add a new project to get started!</Typography>
          ):''}
        </Grid>
        <Grid item xs={12} md={12}>
          {/* {selectedProject && (
            <>
            
              <div style={{position:'absolute', top:'20%', width:'70%', display:'None'}}>
              <ProjectDetails project={selectedProject} />
              <CommunicationThread
                projectId={selectedProject._id}
                messages={messages}
                onSendMessage={handleSendMessage}
              />
              </div>
            </>
          )} */}
        </Grid>
      </Grid>
    </Box>

    {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{marginLeft:'50px',marginRight:'50px'}}>
          <ProjectDetails project={selectedProject} />
          <div style={{margin:'40px'}}>
              
              <CommunicationThread
                projectId={selectedProject._id}
                messages={messages}
                onSendMessage={handleSendMessage}
              />
              </div>

          
        </div>
      </Modal> */}

      <Dialog
        fullScreen
        open={mopen}
        onClose={handleMClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleMClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {/* {selectedProject && (selectedProject.name)} */}
            </Typography>
            
          </Toolbar>
          
        </AppBar>
        {selectedProject && (
            <>
              <div style={{marginLeft:'50px',marginRight:'50px'}}>
          <ProjectDetails project={selectedProject} />
          

          
        </div>
              <div style={{margin:'40px'}}>
              
              <CommunicationThread
                projectId={selectedProject._id}
                messages={messages}
                onSendMessage={handleSendMessage}
              />
              </div>
              {/* {selectedProject._id} */}
            </>
          )} 
        
      </Dialog>
    </>
    
  );
}

export default Projects;
import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { Chip } from '@mui/material';
import RequestAttach from './RequestAttach';
import { Navigate } from 'react-router-dom';

export default function AccordionExpandIcon() {
  const [users, setRequests] = useState([]);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let role1 = JSON.parse(sessionStorage.getItem('user'));
  const role = role1.role;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (role === 1){
          const response = await axios.get('https://ideawork.in/api/requests');
          setRequests(response.data);
        }
        else {
          const response = await axios.get(`https://ideawork.in/api/requests/speci/${role1.id}`);
          setRequests(response.data);
        }
        

      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);

  return (
    <div style={{ marginTop: '20px', marginLeft: '5%', marginRight: '5%' }}>
      { !role1 ? <Navigate to="/login"/>:''}
      {users.map((data) =>
      (
        <Accordion expanded={expanded === data._id} onChange={handleChange(data._id)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography wrapped sx={{ width: '74%', flexShrink: 0 }}>
              <strong>{data.subject}</strong>
            </Typography>
            <Chip
              sx={{
                position: 'absolute',
                right: 55,
                top: 10,

              }}
              label={data.appstatus === 3 ? 'Approved' : data.appstatus === 1 ? 'Reject': data.appstatus === 2 ? 'Hold' : 'Not Seen' } color={data.appstatus === 3 ? 'success' : data.appstatus === 1 ? 'error': data.appstatus === 2 ? 'warning' : 'default'} />
          </AccordionSummary>
          <AccordionDetails>
            <Typography mt={2}><strong>Message : </strong>{data.message}</Typography>
            <Typography>

            </Typography>
            <Typography mt={2}><strong>Attachments : </strong></Typography>
            <RequestAttach attach={data._id} />
          </AccordionDetails>

        </Accordion>
      )
      )}

    </div>
  );
}

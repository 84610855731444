import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel,  Container, Typography } from '@mui/material';
import axios from 'axios';
import Stack from '@mui/material/Stack';



export default function Userlist({ selectedItems, onSelectionChange, title}) {
  const [users, setUsers] = useState([]);
 

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://ideawork.in/api/users/list');
        
          setUsers(response.data);
          
                  
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);


  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    onSelectionChange(name, checked); // Update selection in parent component
  
  };

  const handleButtonClick = () => {
    const checkedItems = Object.keys(selectedItems).filter((item) => selectedItems[item]);
    
  };
  

  

  return (
    
    <Container>
      {/* {console.log(selectedItems = alreadyChecked)} */}
      <Typography variant="h6" gutterBottom>
        {title == undefined ? "Select User" : title}
      </Typography>
      {/* <Stack direction={"row"}>
      <TextField  onChange={handleSearchChange} value={Nsearch} label="Name" id="name" variant="standard" />
      <TextField  onChange={handleSearchChange} value={Dsearch} label="Designation" id="designation" variant="standard" />
      <Button variant="outlined"><SearchIcon/></Button>
      </Stack> */}
      <Stack>
      {users.map((user) => (
        <FormControlLabel
          key={user._id}
          control={
            
              <Checkbox
              name={user._id}
              checked={!!selectedItems[user._id] }
              onChange={handleCheckboxChange}
            />
            
          }
          label={user.username}
        />
      ))}
      </Stack>
      {/* <Button variant="contained" color="primary" onClick={handleButtonClick}>
        Get Selected
      </Button> */}
    </Container>
  );
}
Userlist.defaultProps = {
  title: "Select Users"
};



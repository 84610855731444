import React, { useState } from 'react';
import {
  TextField,
  Button,
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import axios from 'axios';
import RequestList from './RequestList';
import Userlist from './Userlist';

const RequestForm = () => {
  const [formValues, setFormValues] = useState({
    subject: '',
    message: '',
  });
  const [attachments, setAttachments] = useState([]);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});

  let role1 = JSON.parse(sessionStorage.getItem('user'));
  const role = role1.role;
  
  const checkedUsers = Object.keys(selectedItems).filter((item) => selectedItems[item]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setAttachments(Array.from(e.target.files));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append('to', checkedUsers);
    formData.append('sub', formValues.subject);
    formData.append('message', formValues.message);
    formData.append('submuserid', role1.id);

    for (const file of attachments) {
      formData.append('attachments', file);
    }

    try {
      const response = await axios.post('https://ideawork.in/api/requests/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },timeout: 600000,
      });

      setIsFormVisible(false); // Hide the form after submission
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsSubmitting(false);
      setFormValues({
        subject: '',
        message: '',
      });
      setAttachments([]);
    }
  };

  const handleCreateRequestClick = () => {
    setIsFormVisible(true);
  };

  const handleCloseClick = () => {
    setIsFormVisible(false);
    setFormValues({
      subject: '',
      message: '',
    });
    setAttachments([]); 
  };

  const handleSelectionChange = (name, checked) => {
    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      [name]: checked,
    }));
  };

  return (
    <div>
      {!isFormVisible ? (
        <>
          <Button variant="contained" color="primary" onClick={handleCreateRequestClick}>
            Create Request
          </Button>
          <RequestList />
        </>
      ) : (
        <Paper elevation={3} style={{ padding: '2rem', maxWidth: '600px', margin: 'auto' }}>
          <Typography variant="h5" gutterBottom>
            Submit a Request
          </Typography>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack>
                  <Userlist selectedItems={selectedItems} title={'To'} onSelectionChange={handleSelectionChange} />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Subject"
                  variant="outlined"
                  fullWidth
                  name="subject"
                  value={formValues.subject}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Message"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  name="message"
                  value={formValues.message}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" component="label">
                  Attach Files
                  <input
                    type="file"
                    multiple
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
                <List>
                  {attachments.map((file, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={file.name} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </Button>
                  <Button style={{ marginLeft: '5px' }} variant="outlined" onClick={handleCloseClick} color="primary" disabled={isSubmitting}>
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Paper>
      )}
    </div>
  );
};

export default RequestForm;

import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import SingleUser from './SingleUser';
import SubTask from './SubTask';
import { Stack } from '@mui/material';
import ParallelTask from './ParallelTask';

export default function TaskTimeline(props) {
    const [tasks, setTasks] = useState([]);
    // const[Nsearch, setNSearch] = useState("")
    // const[count, setCount] = useState(1)

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const response = await axios.get(`https://ideawork.in/api/tasks/timeline/${props.project}`);

                setTasks(response.data);

            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        fetchTasks();
    }, []);

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    var i = 1 ;
    return (
        
        <div>
            {tasks.map((data) =>
            (
                <>
                <Chip style={{marginTop:'5px'}} label={ `Step - ${i++}`} />
                <Accordion style={{marginTop:'2px'}} expanded={expanded === data._id} onChange={handleChange(data._id)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <div style={{display:'flex',flexWrap:'wrap',gap:'2px'}}>
                        <Typography sx={{ width: '75%', flexShrink: 0 }}>
                            <strong>{data.title}</strong>
                        </Typography>
                        <Chip align="right" label={data.status} color={data.status === 'Completed' ? 'success' : data.status === 'In Progress' ? 'warning' : 'default'} />
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <strong>Description : </strong> {data.description}
                        </Typography>
                        <Typography>
                            <strong>Due Date : </strong> {data.dueDate}
                        </Typography>
                        <Typography>
                            <strong>Assigned to : </strong> <Stack direction="row" gap={1}>{(data.selectedUser).map(data => (
                                <SingleUser ml={1} user={data} type="" />
                            ))}</Stack>
                        </Typography>
                        <Typography mt={1}>
                            {/* <strong>Sub Tasks : </strong> <SubTask task={data._id} /> */}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <ParallelTask ptask={data._id} num={i-1}/>
                </>

            )
            )}

        </div>
    );
}

import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Chip } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import ProjectName from './ProjectName';

function TaskList({ tasks, onEditTask, onDeleteTask, onViewTask }) {
  
  const renderTaskRows = (task, index, showProjectName) => {
    return (
      <React.Fragment key={task._id}>
        {showProjectName && (
          <TableRow>
            <TableCell colSpan={7} sx={{ fontWeight: 'bold' }}>
              <ProjectName id={task.project} />
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell align="right">{index + 1}</TableCell>
          <TableCell>{task.title}</TableCell>
          <TableCell align="right">
            <Chip label={task.status} color={task.status === 'Completed' ? 'success' : 'default'} />
          </TableCell>
          <TableCell align="right">
            <Chip
              label={task.priority}
              color={
                task.priority === 'High' ? 'error' :
                task.priority === 'Medium' ? 'warning' :
                'default'
              }
            />
          </TableCell>
          <TableCell align="right">{task.dueDate}</TableCell>
          <TableCell align="right">{task.estimatedTime} / {task.actualTime || 0}</TableCell>
          <TableCell align="right">
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <IconButton onClick={() => onViewTask(task._id)}><VisibilityIcon /></IconButton>
              <IconButton onClick={() => onEditTask(task._id)}><EditIcon /></IconButton>
              <IconButton onClick={() => onDeleteTask(task._id)}><DeleteIcon /></IconButton>
            </div>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  const renderTasksGroupedByProject = () => {
    const groupedTasks = tasks.reduce((acc, task) => {
      if (!acc[task.project]) {
        acc[task.project] = [];
      }
      acc[task.project].push(task);
      return acc;
    }, {});

    return Object.entries(groupedTasks).map(([projectId, projectTasks]) => {
      return projectTasks.map((task, index) => {
        const showProjectName = index === 0;
        return renderTaskRows(task, index, showProjectName);
      });
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Title</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Priority</TableCell>
            <TableCell align="right">Due Date</TableCell>
            <TableCell align="right">Time (Est / Act)</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderTasksGroupedByProject()}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TaskList;

import React, {  useState, useEffect } from 'react'
import axios from 'axios';
// import socket from '../utils/socket';
import "./user.css";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';




export default function Users() {

    const [users, setUsers] = useState([]);
    // const [editMode, setEditmode] = useState();
   
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get('https://ideawork.in/api/users');
                setUsers(response.data)
                //console.log(response.data)
               
            } catch (error) {
                console.error('Error fetching:', error);

            }
            

        };
        fetchUsers();
        //console.log(users)
    }, [])
   

    return (
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align='center'>Name</TableCell>
            <TableCell align="center">Email</TableCell>
            {/* <TableCell align="center">Designation</TableCell> */}
            {/* <TableCell align="center">Operation</TableCell> */}
            
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center"><input className='tableinput' value={row.username} /></TableCell>
              <TableCell align="center">{row.email}</TableCell>
            
              {/* <TableCell align="center">{
                row.role === 0 ? "Employee" : row.role === 1 ? "Manager" : row.role === 2 ? 'Technical Consultant' :'Invalid User'
                }</TableCell> */}
              {/* <TableCell align="center"></TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    )
}

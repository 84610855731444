import React, { createContext, useState, useContext, useEffect } from 'react';
import socket from '../utils/socket';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
      socket.emit('login', parsedUser.id);
    }
  }, []);

  const login = (userData) => {
    setUser(userData);
    
    sessionStorage.setItem('user', JSON.stringify(userData));
    // const user = JSON.parse(sessionStorage.getItem("user"));
    //console.log(user)
    socket.emit('login', userData.id);
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
    socket.emit('logout');
  };


  const register = (userData) => {
    const newUser = { ...userData, role: 'user' };
    setUser(newUser);
    localStorage.setItem('user', JSON.stringify(newUser));
  };

  const updateProfile = (updatedData) => {
    const updatedUser = { ...user, ...updatedData };
    setUser(updatedUser);
    localStorage.setItem('user', JSON.stringify(updatedUser));
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, register, updateProfile }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Chip } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Visibility as VisibilityIcon } from '@mui/icons-material';

function EquipmentList({ equipments, onEditEquipment, onDeleteEquipment, onViewEquipment }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Next Maintenance</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {equipments.map((equipment) => (
            <TableRow key={equipment.id}>
              <TableCell component="th" scope="row">
                {equipment.name}
              </TableCell>
              <TableCell align="right">{equipment.type}</TableCell>
              <TableCell align="right">
                <Chip 
                  label={equipment.status} 
                  color={equipment.status === 'Operational' ? 'success' : equipment.status === 'Under Maintenance' ? 'warning' : 'error'} 
                />
              </TableCell>
              <TableCell align="right">{equipment.nextMaintenanceDate}</TableCell>
              <TableCell align="right">
                <IconButton onClick={() => onViewEquipment(equipment.id)}><VisibilityIcon /></IconButton>
                <IconButton onClick={() => onEditEquipment(equipment.id)}><EditIcon /></IconButton>
                <IconButton onClick={() => onDeleteEquipment(equipment.id)}><DeleteIcon /></IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default EquipmentList;
import React, { useState } from 'react';
import { Typography, Box, Button, Grid } from '@mui/material';
import EquipmentList from '../components/EquipmentList';
import EquipmentForm from '../components/EquipmentForm';
import EquipmentDetails from '../components/EquipmentDetails';

function Equipment() {
  const [equipments, setEquipments] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editingEquipment, setEditingEquipment] = useState(null);
  const [selectedEquipment, setSelectedEquipment] = useState(null);

  const handleAddEquipment = (newEquipment) => {
    setEquipments([...equipments, { ...newEquipment, id: Date.now() }]);
    setShowForm(false);
  };

  const handleEditEquipment = (equipmentId) => {
    const equipmentToEdit = equipments.find(e => e.id === equipmentId);
    setEditingEquipment(equipmentToEdit);
    setShowForm(true);
  };

  const handleUpdateEquipment = (updatedEquipment) => {
    setEquipments(equipments.map(e => e.id === updatedEquipment.id ? updatedEquipment : e));
    setShowForm(false);
    setEditingEquipment(null);
  };

  const handleDeleteEquipment = (equipmentId) => {
    setEquipments(equipments.filter(e => e.id !== equipmentId));
    if (selectedEquipment && selectedEquipment.id === equipmentId) {
      setSelectedEquipment(null);
    }
  };

  const handleViewEquipment = (equipmentId) => {
    const equipmentToView = equipments.find(e => e.id === equipmentId);
    setSelectedEquipment(equipmentToView);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Equipment
      </Typography>
      <Button 
        variant="contained" 
        onClick={() => {
          setShowForm(!showForm);
          setEditingEquipment(null);
        }}
        sx={{ mb: 2 }}
      >
        {showForm ? 'Cancel' : 'Add New Equipment'}
      </Button>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {showForm && (
            <EquipmentForm 
              onSubmit={editingEquipment ? handleUpdateEquipment : handleAddEquipment} 
              initialData={editingEquipment}
            />
          )}
          {equipments.length > 0 ? (
            <EquipmentList 
              equipments={equipments} 
              onEditEquipment={handleEditEquipment}
              onDeleteEquipment={handleDeleteEquipment}
              onViewEquipment={handleViewEquipment}
            />
          ) : (
            <Typography>No equipment yet. Add new equipment to get started!</Typography>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {selectedEquipment && <EquipmentDetails equipment={selectedEquipment} />}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Equipment;
import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Chip,
  Typography,
  Box,
  TextField,
  Stack,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Chat as ChatIcon,
} from '@mui/icons-material';

function ProjectList({ projects, onEditProject, onDeleteProject, onViewProject, onViewMessage }) {
  let role1 = JSON.parse(sessionStorage.getItem('user'));
  const role = role1.role;

  const [filter, setFilter] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const filteredProjects = projects.filter((project) => {
    const matchesName = project.name.toLowerCase().includes(filter.toLowerCase());
    const matchesStartDate = !startDate || new Date(project.startDate) >= new Date(startDate);
    const matchesEndDate = !endDate || new Date(project.endDate) <= new Date(endDate);

    return matchesName && matchesStartDate && matchesEndDate;
  });

  return (
    <div>
      <div style={{display:'flex',flexWrap:'wrap',gap:'10px'}}>
        <div>
        <TextField
          label="Filter by Project Name"
          variant="outlined"
          fullWidth
          value={filter}
          onChange={handleFilterChange}
        />
        </div>
        <div>
        <TextField
          label="Start Date"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={startDate}
          onChange={handleStartDateChange}
        />
        </div>
        <div>
        <TextField
          label="End Date"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={endDate}
          onChange={handleEndDateChange}
        />
        </div>
      </div>
      <List>
        {filteredProjects.map((project) => (
          <ListItem
            key={project._id}
            alignItems="flex-start"
            sx={{
              mb: 2,
              borderRadius: 2,
              border: '1px solid #e0e0e0',
              '&:hover': {
                backgroundColor: '#f5f5f5',
              },
            }}
          >
            <ListItemText
              primary={
                <Typography variant="h6" component="div">
                  {project.name}
                </Typography>
              }
              secondary={
                <Box>
                  <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
                    <Typography variant="body2" mr={10} color="text.secondary" gutterBottom>
                      {project.description}
                    </Typography>
                    <Chip
                      label={project.status}
                      mr={20}
                      color={
                        project.status === 'Completed'
                          ? 'success'
                          : project.status === 'In Progress'
                          ? 'warning'
                          : project.status === 'On Hold'
                          ? 'error'
                          : 'default'
                      }
                      size="small"
                    />
                    <Chip label={"From : " + project.startDate} mr={2} size="small" />
                    <Chip label={"To : " + project.endDate} mr={2} size="small" />
                  </div>
                </Box>
              }
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="view" onClick={() => onViewMessage(project._id)}>
                <VisibilityIcon />
              </IconButton>
              {role === 1 || role === 2 ? (
                <>
                  <IconButton edge="end" aria-label="edit" onClick={() => onEditProject(project._id)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="delete" onClick={() => onDeleteProject(project._id)}>
                    <DeleteIcon />
                  </IconButton>
                </>
              ) : (
                ''
              )}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default ProjectList;

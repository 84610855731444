import React, { useEffect, useState } from 'react';
import { Typography, Box, Paper, Chip, LinearProgress, Grid, Stack } from '@mui/material';
// import ProjectGanttChart from './ProjectGanttChart';
import TaskTimeline from './TaskTimeline';
import SingleUser from './SingleUser';
import axios from 'axios';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Margin } from '@mui/icons-material';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function ProjectDetails({ project }) {

  const [open, setOpen] = React.useState(false);


  const [counts, setCounts] = useState();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`https://ideawork.in/api/tasks/countall/${project._id}`);
        const response1 = await axios.get(`https://ideawork.in/api/tasks/count/${project._id}`);

        setCounts((response1.data / response.data) * 100);

      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);

  if (!project) return null;


  const getProgressColor = (progress) => {
    if (progress < 30) return 'error';
    if (progress < 70) return 'warning';
    return 'success';
  };
  //   const price_formatted = project.startDate;
  // const price=typeof price_formatted==="string" ?price_formatted.split('T')[0]:"";
  //  //console.log(price)




  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (

    <>
      <Paper sx={{ p: 2, mt: 2 }}>
        <Typography variant="h5" gutterBottom>{project.name}</Typography>
        <Box sx={{ mb: 2 }}>
          <Chip
            label={project.status}
            color={project.status === 'Completed' ? 'success' : project.status === 'In Progress' ? 'warning' : 'default'}
          />
        </Box>

        <Typography variant="body1" paragraph><strong>Description:</strong> {project.description}</Typography>

        <Grid container spacing={2} >
          <Grid item xs={12} md={6}>
            <Typography variant="body1"><strong>Start Date:</strong> {project.startDate}</Typography>
            <Typography variant="body1"><strong>End Date:</strong> {project.endDate}</Typography>
            <Typography variant="body1"><strong>Budget:</strong> ₹{project.budget}</Typography>
            <Typography variant="body1"><strong>Resources:</strong> {project.resources}</Typography>
            <Typography variant="body1"><strong>Members:</strong></Typography>
            <div style={{ display: 'flex', flexWrap: "wrap", gap: "2px", }}>
              {(project.selectedUser).map(data => (
                <SingleUser user={data} type="" />
              ))}
            </div>
            <Box sx={{ mt: 2, mb: 4 }}>
              <Typography variant="body1"><strong>Progress:</strong></Typography>
              <Box display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                  <LinearProgress variant="determinate" value={counts} color={getProgressColor(counts)} />
                </Box>
                <Box minWidth={35}>
                  <Typography variant="body2" color="text.secondary">{`${Math.round(counts)}%`}</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>Project Timeline</Typography>
            <div style={{ maxHeight: '245px', overflow: 'hidden' }}>
              <TaskTimeline project={project._id} />
              
            </div>
            <Button variant="contained" onClick={handleClickOpen}>
                Expand
              </Button>
          </Grid>

        </Grid>

      </Paper>

      <React.Fragment>

        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Expanded View
              </Typography>
              <Button autoFocus color="inherit" onClick={handleClose}>
                Close
              </Button>
            </Toolbar>
          </AppBar>
          <div style={{margin:'2vh'}}>
            <TaskTimeline project={project._id} />
          </div>
        </Dialog>
      </React.Fragment>

    </>


  );
}

export default ProjectDetails;
import React, { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import { Box, Button, Stack } from '@mui/material';

import axios from 'axios';

export default function RequestAttach(props) {
    const [users, setUsers] = useState([]);
    // const[Nsearch, setNSearch] = useState("")
    // const[Dsearch, setDSearch] = useState("")

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`https://ideawork.in/api/requests/single/${props.attach}`);

                setUsers(response.data.attachments);
                //console.log(users)

            } catch (error) {
                console.error('Error fetching attachment:', error);
            }
        };
        fetchUsers();
    }, []);

    return (
        <div style={{display:'flex', flexWrap:'wrap', gap:'5px', marginTop:'5px', marginLeft:'10px'}}>
       { users.map((data) => (
          
            
                <Button
                    variant="outlined"
                    size="small"
                    href={`https://upload.ideawork.in/${data.path}`}
                    download={data.filename}
                    target='_Blank'
                > {data.filename}
                </Button>
            
            
         

        ))}
        </div>

    )
}


import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { TextField, Button, Box, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import socket from '../utils/socket';
import Snackbar from '@mui/material/Snackbar';

function RegisterForm({ onRegister }) {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setError('');
    //console.log(formData)

    if (formData.password !== formData.confirmPassword) {
      setError(<Alert severity="warning">Password is not Maching</Alert>);


      return;
    } else {
      try {
        const response = await axios.get(`https://ideawork.in/api/users/${formData.email}`);
        //console.log(response.data.email)
        if (response.data.email === formData.email) {
          
          setError(<Alert severity="error">You are already Registered !</Alert>);
            setTimeout(() => {
              setError('');
            }, 3000);

        }

      } catch (error) {
        console.error('Error:', error);

        const response = await axios.post('https://ideawork.in/api/users', formData);
          socket.emit('addUser', response.data);
          setOpen(true);
          setTimeout(() => {
            navigate('/login');
          }, 2000);

      }
    }

    // onRegister(formData);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>

      <TextField
        fullWidth
        label="Full Name"
        name="username"
        value={formData.username}
        onChange={handleChange}
        margin="normal"
        required
      />
      <TextField
        fullWidth
        label="Email"
        name="email"
        type="email"
        value={formData.email}
        onChange={handleChange}
        margin="normal"
        required
      />
      <TextField
        fullWidth
        label="Password"
        name="password"
        type="password"
        value={formData.password}
        onChange={handleChange}
        margin="normal"
        required
      />
      <TextField
        fullWidth
        label="Confirm Password"
        name="confirmPassword"
        type="password"
        value={formData.confirmPassword}
        onChange={handleChange}
        margin="normal"
        required
      />
      <Snackbar open={open}>
        <Alert

          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Registered successfully!
        </Alert>
      </Snackbar>
      {error && <Typography color="error">{error}</Typography>}
      
      <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 1, mb: 2 }}
          >
           Register
          </Button>
          <Link to="/login" style={{ textDecoration: 'none' }}>
            <Typography variant="body2" color="primary" align="center">
              Do you have an account? SignIn here
            </Typography>
          </Link>
    </Box>
  );
}

export default RegisterForm;
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { Typography, Button,Chip } from '@mui/material';
import RequestAttach from './RequestAttach';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AdminReqDetail({ open, onClose, id }) {
    const [userDetail, setUserDetail] = useState(null);

   
    useEffect(() => {
        const fetchUserDetail = async () => {
            try {
                if (id) { 
                    const response = await axios.get(`https://ideawork.in/api/requests/fulldetail/${id}`);
                    const response1 = await axios.put(`https://ideawork.in/api/requests/update/${id},appstatus,1`);
                    setUserDetail(response.data);
                }
            } catch (error) {
                console.error('Error fetching user detail:', error);
            } 
        };
      
        fetchUserDetail();
    }, [id]); 

    
    const onReject = () => {
        const fetchUserDetail = async () => {
            try {
                if (id) { 
                    const response = await axios.put(`https://ideawork.in/api/requests/update/${id},appstatus,1`);
                    onClose()
                }
            } catch (error) {
                console.error('Error fetching user detail:', error);
            }
        };
        fetchUserDetail()
    };
    const onHold = () => {
        const fetchUserDetail = async () => {
            try {
                if (id) { 
                    const response = await axios.put(`https://ideawork.in/api/requests/update/${id},appstatus,2`);
                    onClose()
                }
            } catch (error) {
                console.error('Error fetching user detail:', error);
            }
        };
        fetchUserDetail()

    };
    const onApprove =() => {
        const fetchUserDetail = async () => {
            try {
                if (id) { 
                    const response = await axios.put(`https://ideawork.in/api/requests/update/${id},appstatus,3`);
                    onClose()
                }
            } catch (error) {
                console.error('Error fetching user detail:', error);
            }
        };
        fetchUserDetail()
    }
   

    return (
        <BootstrapDialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                {userDetail ? userDetail.subject : 'Loading...'}
                {userDetail && userDetail.appstatus === 2 ? ( <Chip
                    sx={{
                        position: 'absolute',
                        right: 55,
                        top: 10,
                        
                    }}
                     label="Hold" color='warning'/>) : ''}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    {userDetail ? userDetail.message : 'Loading...'}
                </Typography>
                {userDetail ? <RequestAttach attach={userDetail._id} /> : ''}
            </DialogContent>
            
            {/* <RequestAttach attach={userDetail} /> */}
            <DialogActions>
                <Button variant="contained" onClick={onApprove}>
                    Approve
                </Button>
                <Button variant="contained" color='warning' onClick={onHold}>
                    Hold
                </Button>
                <Button variant="contained" color='error' onClick={onReject}>
                    Reject
                </Button>
                <Button variant='outlined' autoFocus onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}

import Chip from '@mui/material/Chip';
import React, { useState,useEffect } from 'react';

import axios from 'axios';

export default function SingleUser(props) {
    const [users, setUsers] = useState([]);
  // const[Nsearch, setNSearch] = useState("")
  // const[Dsearch, setDSearch] = useState("")

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`https://ideawork.in/api/users/single/${props.user}`);
        
          setUsers([response.data]);
                  
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);

  return (
    users.map( (data) => (
        <Chip label={data.username} />
    ))
    
  )
}


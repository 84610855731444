import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Box, Button, TextField, MenuItem, Grid } from '@mui/material';
import TaskList from '../components/TaskList';
import axios from 'axios';
import TaskForm from '../components/TaskForm';
import socket from '../utils/socket';
import { useNotifications } from '../contexts/NotificationContext';
import TaskDetails from '../components/TaskDetails';
import { Navigate } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function Tasks() {
  let role1 = JSON.parse(sessionStorage.getItem('user'));
  const [tasks, setTasks] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [filterPriority, setFilterPriority] = useState('All');
  const [sortBy, setSortBy] = useState('dueDate');
  const [mopen, setMOpen] = useState(false);
  const handleMClose = () => setMOpen(false);

  const { addNotification } = useNotifications();

  // let role1 = JSON.parse(sessionStorage.getItem('user'));
  const role = role1.role;
  

  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });

  const handleAddTask = async (newTask) => {
    
    try {
      const response = await axios.post('https://ideawork.in/api/tasks', newTask);
      setTasks(tasks => [...tasks, response.data]);
      // setTasks([...tasks, response.data]);
      setShowForm(false);
      socket.emit('addTask', response.data);
      addNotification({
        id: Date.now(),
        title: 'New Task',
        message: `A new Task "${newTask.title}" has been added.`
      });
    } catch (error) {
      console.error('Error adding Task:', error);
      addNotification({
        id: Date.now(),
        title: 'Error',
        message: 'Failed to add task.'
      });
    }
  };

  const handleEditTask = (taskId) => {
    const taskToEdit = tasks.find(t => t._id === taskId);
    setEditingTask(taskToEdit);
    //console.log(Object.keys(taskToEdit) + "this is")
    setShowForm(true);
  };

  

  const handleUpdateTask = async (updatedTask) => {
    try {
      const response = await axios.put(`https://ideawork.in/api/tasks/${updatedTask._id}`, updatedTask);
      setTasks(prevTasks =>
        prevTasks.map(t => t._id === updatedTask._id ? response.data : t,)
      );
      setShowForm(false);
      setEditingTask(null);
      socket.emit('updateTask', response.data);
      addNotification({
        id: Date.now(),
        title: 'Task Updated',
        message: `Task "${updatedTask.title}" has been updated.`
      });
    } catch (error) {
      console.error('Error updating task:', error);
      addNotification({
        id: Date.now(),
        title: 'Error',
        message: 'Failed to update task.'
      });
    }
  };

  
  const handleDeleteTask = async (taskId) => {
    try {
      //console.log('Attempting to delete task with ID:', taskId);
      const response = await axios.delete(`https://ideawork.in/api/tasks/${taskId}`);
      //console.log('Delete response:', response.data);

      if (response.data && response.data.message === 'Task deleted successfully') {
        setTasks(prevTasks => prevTasks.filter(p => p._id !== taskId));
        if (selectedTask && selectedTask._id === taskId) {
          setSelectedTask(null);
        }
        socket.emit('deleteTask', taskId);
        addNotification({
          id: Date.now(),
          title: 'Task Deleted',
          message: 'The Task has been successfully deleted.'
        });
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Error deleting task:', error.response ? error.response.data : error.message);
      addNotification({
        id: Date.now(),
        title: 'Error',
        message: `Failed to delete task: ${error.response ? error.response.data.message : error.message}`
      });
    }
  };

  const handleViewTask = (taskId) => {
    const taskToView = tasks.find(t => t._id === taskId);
    setSelectedTask(taskToView);

    setMOpen(true)


  };

  const fetchTasks = useCallback(async () => {
    try {
      if (role === 0){
        
        const response = await axios.get(`https://ideawork.in/api/tasks/speci/${role1.id}`);
        setTasks(response.data);
      }
      else {
        const response = await axios.get('https://ideawork.in/api/tasks');
        setTasks(response.data);
      }
      
      
    } catch (error) {
      console.error('Error fetching projects:', error);
      addNotification({
        id: Date.now(),
        title: 'Error',
        message: 'Failed to fetch projects.'
      });
    }
  }, [addNotification]);

  

  useEffect(() => {
    fetchTasks();

    socket.on('tasksAdded', (task) => {
      setTasks(prevTasks => [...prevTasks, task]);
      addNotification({
        id: Date.now(),
        title: 'New Tasks',
        // message: `A new Tasks "${newTask.title}" has been added.`
      });
    });

    socket.on('taskUpdated', (updatedTask) => {
      setTasks(prevTasks =>
        prevTasks.map(t => t._id === updatedTask._id ? updatedTask : t)
      );
      addNotification({
        id: Date.now(),
        title: 'Task Updated',
        message: `Task "${updatedTask.title}" has been updated.`
      });
    });

    socket.on('projectDeleted', (taskId) => {
      setTasks(prevTasks => prevTasks.filter(t => t._id !== taskId));
      addNotification({
        id: Date.now(),
        title: 'Project Deleted',
        message: 'A task has been deleted.'
      });
    });

    return () => {
      socket.off('taskAdded');
      socket.off('taskUpdated');
      socket.off('taskDeleted');
    };
  }, [fetchTasks, addNotification]);



  const filteredAndSortedTasks = tasks
    .filter(task => filterPriority === 'All' || task.priority === filterPriority)
    .sort((a, b) => {
      if (sortBy === 'dueDate') {
        return new Date(a.dueDate) - new Date(b.dueDate);
      } else if (sortBy === 'priority') {
        const priorityOrder = { 'High': 1, 'Medium': 2, 'Low': 3 };
        return priorityOrder[a.priority] - priorityOrder[b.priority];
      }
      return 0;
    });
    
    
  return (
    <>
    
      <Box sx={{ flexGrow: 1, p: 3 }}>
        
        <Typography variant="h4" gutterBottom>Tasks</Typography>
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
          {role === 1 || role === 2 ?
            <Button
              variant="contained"
              onClick={() => {
                setShowForm(!showForm);
                setEditingTask(null);
              }}
            >
              {showForm ? 'Cancel' : 'Add New Task'}
            </Button> :
            (showForm ? (
              <Button
                variant="contained"
                onClick={() => {
                  setShowForm(!showForm);
                  setEditingTask(null);
                }}
              >
                Cancel
              </Button>
            ) : '')
          }
          <Box>
            <TextField
              select
              label="Filter by Priority"
              value={filterPriority}
              onChange={(e) => setFilterPriority(e.target.value)}
              sx={{ mr: 2, minWidth: 150 }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Low">Low</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="High">High</MenuItem>
            </TextField>
            <TextField
              select
              label="Sort by"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              sx={{ minWidth: 150 }}
            >
              <MenuItem value="dueDate">Due Date</MenuItem>
              <MenuItem value="priority">Priority</MenuItem>
            </TextField>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={showForm ? 12 : 12}>
            {showForm && (
              <TaskForm
                onSubmit={editingTask ? handleUpdateTask : handleAddTask}
                initialData={editingTask}
                allTasks={tasks}
                userrole = {role}
              />
            )}
            {filteredAndSortedTasks.length > 0 && showForm === false ? (
              <TaskList
                tasks={filteredAndSortedTasks}
                onEditTask={handleEditTask}
                onDeleteTask={handleDeleteTask}
                onViewTask={handleViewTask}
              />
            ) : showForm === false ? (
              <Typography>No tasks yet. Add a new task to get started!</Typography>
            ) : ""}
          </Grid>
         
        </Grid>
      </Box>
      <Dialog
        fullScreen
        open={mopen}
        onClose={handleMClose}
      // TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleMClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              
            </Typography>

          </Toolbar>

        </AppBar>
        {selectedTask && (
          <>
            <div style={{ marginLeft: '50px', marginRight: '50px' }}>
              <TaskDetails task={selectedTask} onClose={() => setSelectedTask(null)} />



            </div>

          </>
        )}

      </Dialog>
    </>
  );
}

export default Tasks;
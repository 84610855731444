import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { List, ListItem, ListItemText, TextField, Button, Typography, Box, IconButton, CircularProgress, } from '@mui/material';
import { Send as SendIcon, AttachFile as AttachFileIcon } from '@mui/icons-material';

function CommunicationThread({ projectId, onMessageSent }) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [attachment, setAttachment] = useState([]);
  const [shattachment, setshAttachment] = useState([]);
  const [loading, setLoading] = useState(false);

  let role1 = JSON.parse(sessionStorage.getItem('user'));
  const role = role1.role;

  const fetchMessages = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://ideawork.in/api/projects/${projectId}/messages`);
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
    setLoading(false);
  }, [projectId]);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  const handleSendMessage = async () => {
    console.log(attachment);
    if (newMessage.trim() !== '' || attachment) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('text', newMessage);
        formData.append('sender', role1.username); // Replace with actual user info
        //console.log(formData)
        for (const file of attachment) {
          formData.append('attachments', file);
        }
        // if (attachment) {
        //   formData.append('attachment', attachment); 
        //   //console.log(formData)
        // }
        console.log(attachment)

        const response = await axios.post(`https://ideawork.in/api/projects/${projectId}/messages`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setMessages([...messages, response.data]);

        setNewMessage('');
        setAttachment(null);
        setshAttachment([])
        if (onMessageSent) {
          onMessageSent(response.data);
        }
      } catch (error) {
        console.error('Error sending message:', error);
      }
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    setAttachment(e.target.files);
    setshAttachment(Array.from(e.target.files));
    console.log(attachment)
  };
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Communication Thread
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <List>
          {messages.map((message) => (
            <ListItem key={message._id || message.timestamp} alignItems="flex-start">
              <ListItemText
                primary={message.text}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {message.sender}
                    </Typography>
                    {" — "}{new Date(message.timestamp).toLocaleString()}
                    <div style={{display:'flex',flexWrap:'wrap',gap:'5px'}}>
                          {message.attachment && (

                            message.attachment.map((data) =>
                              <Box>
                                <Button
                                 variant="outlined"
                                 size="small"
                                 href={`https://upload.ideawork.in${data.path}`}
                                 download={data.originalName}
                                 rel="noopener noreferrer"
                                 target='_blank'
                                >
                                  {data.originalName}
                                </Button>
                              </Box>

                            )
                          )}
                          </div>
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
        </List>
      )}
      <Box display="flex" alignItems="center" mt={2}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type your message"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <input
          accept="image/*,application/pdf,.doc,.docx,video/*"
          style={{ display: 'none' }}
          id="attachment-button-file"
          type="file"
          multiple
          onChange={handleFileChange}
        />
        <label htmlFor="attachment-button-file">
          <IconButton component="span">
            <AttachFileIcon />
          </IconButton>
        </label>
        <Button
          variant="contained"
          color="primary"
          endIcon={<SendIcon />}
          onClick={handleSendMessage}
          sx={{ ml: 1 }}
          disabled={loading}
        >
          Send
        </Button>
      </Box>
      {/* {attachment && (
        <Typography variant="body2" mt={1}>
          Attached: {attachment.name}
        </Typography>
      )} */}
      {/* {attachment.length > 0 && (
        <Typography variant="body2" mt={1}>
          Attached files:
        </Typography>
      )} */}
      {shattachment.map((file, index) => (
        <Typography key={index} variant="body2">
          {file.name}
        </Typography>
      ))}
    </Box>
  );
}

export default CommunicationThread;
import React, { useState, useEffect,useNavigate } from 'react';
import Dashboard from '../components/Dashboard';
import axios from 'axios';
import { Navigate } from 'react-router-dom';


function DashboardPage() {
  const [projects, setProjects] = useState([]);
  const [pname, setPname] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [documents, setDocuments] = useState([]);

  

  
  useEffect(() => {
 
    
    const fetchpstatus = async () => {
      try {
        const response = await axios.get('https://ideawork.in/api/projects/status');
        
          setProjects(response.data);
                  
      } catch (error) {
        console.error('Error fetching pstatus:', error);
      }
    };
    fetchpstatus();
    const fetchpname = async () => {
      try {
        const response = await axios.get('https://ideawork.in/api/projects/statusname');
        
        setPname(response.data);
                  
      } catch (error) {
        console.error('Error fetching pname:', error);
      }
    };
    fetchpname();
    const fetchTask = async () => {
      try {
        const response = await axios.get('https://ideawork.in/api/tasks/status');
        
          setTasks(response.data);
                  
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchTask();
    
    // setProjects([
    //   { id: 1, name: 'Project A', status: 'In Progress' },
    //   { id: 2, name: 'Project B', status: 'Completed' },
    //   { id: 2, name: 'Project B', status: 'Completed' },
    //   { id: 3, name: 'Project C', status: 'Not Started' },
    // ]);
    // setTasks([
    //   { id: 1, title: 'Task 1', status: 'In Progress', priority: 'High', dueDate: '2023-07-01' },
    //   { id: 2, title: 'Task 2', status: 'Not Started', priority: 'Medium', dueDate: '2023-07-15' },
    //   { id: 3, title: 'Task 3', status: 'Completed', priority: 'Low', dueDate: '2023-06-30' },
    // ]);
    setEquipment([
      { id: 1, name: 'Machine A', status: 'Operational' },
      { id: 2, name: 'Machine B', status: 'Under Maintenance' },
      { id: 3, name: 'Machine C', status: 'Operational' },
    ]);
    setDocuments([
      { id: 1, title: 'Report Q2', category: 'Report' },
      { id: 2, title: 'Invoice #1234', category: 'Invoice' },
      { id: 3, title: 'Contract XYZ', category: 'Contract' },
    ]);
  }, []);

  // let role1 = JSON.parse(sessionStorage.getItem('user'));
  // // const role = role1.role;
  // if (role1 === role1) {
    
  //   return <Navigate to="/login" />;
  // }else {

  return (
    <>
    
    <Dashboard 
      projects={projects}
      projectname={pname}
      tasks={tasks}
      equipment={equipment}
      documents={documents}
    />
    
    </>
    
  );
} 
// }

export default DashboardPage;
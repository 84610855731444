import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, MenuItem, FormControl } from '@mui/material';
import Userlist from './Userlist';
import Stack from '@mui/material/Stack';
import axios from 'axios';

function TaskForm({ onSubmit, initialData, allTasks, userrole }) {

  const [selectedItems, setSelectedItems] = useState({});
  const [Projects, setProjects] = useState([]);
  const [TasksN, setTasksN] = useState([]);

  const handleSelectionChange = (name, checked) => {
    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      [name]: checked,
    }));
  };
  const [task, setTask] = useState({
    title: '',
    description: '',
    status: 'Not Started',
    priority: 'Medium',
    dueDate: '',
    estimatedTime: '',
    actualTime: '',
    parentTask: 'None',
    dependencies: [],
    project:'None',
    typeTask:'None'
  });

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get('https://ideawork.in/api/projects/name');
        setProjects(response.data);
      } catch (error) {
        console.error('Error fetching Projects:', error);
      }
    };
    fetchProjects();

    const fetchTasksN = async () => {
      try {
        const responseT = await axios.get('https://ideawork.in/api/tasks/name');
        setTasksN(responseT.data);
      } catch (error) {
        console.error('Error fetching Projects:', error);
      }
    };
    fetchTasksN();

    if (initialData) {
      setTask({ ...initialData, _id: initialData._id || undefined });
      
      // Initialize selectedItems based on initialData.selectedUser
      const initialSelectedItems = {};
      initialData.selectedUser.forEach(id => {
        initialSelectedItems[id] = true;
      });
      setSelectedItems(initialSelectedItems);
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTask(prevTask => ({ ...prevTask, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const checkedUsers = Object.keys(selectedItems).filter((item) => selectedItems[item]);

    if (checkedUsers.length > 0) {
      task.selectedUser = checkedUsers;
    }

    onSubmit(task);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      {userrole != 0 ? 
      <>
      <TextField
        select
        fullWidth
        label="Project"
        name="project"
        value={task.project}
        onChange={handleChange}
        margin="normal"
      >
        <MenuItem value="None">None</MenuItem>
        {Projects.map((data) =>
          <MenuItem key={data._id} value={data._id}>{data.name}</MenuItem>
        )}
      </TextField>
      <TextField
        fullWidth
        label="Task Title"
        name="title"
        value={task.title}
        onChange={handleChange}
        margin="normal"
        required
      />
      <TextField
        fullWidth
        label="Description"
        name="description"
        value={task.description}
        onChange={handleChange}
        margin="normal"
        multiline
        rows={4}
      /></>
        :''}
      <TextField
        select
        fullWidth
        label="Status"
        name="status"
        value={task.status}
        onChange={handleChange}
        margin="normal"
      >
        <MenuItem value="Not Started">Not Started</MenuItem>
        <MenuItem value="In Progress">In Progress</MenuItem>
        <MenuItem value="Completed">Completed</MenuItem>
      </TextField>
      {userrole != 0 ? 
      <>
      <TextField
        select
        fullWidth
        label="Priority"
        name="priority"
        value={task.priority}
        onChange={handleChange}
        margin="normal"
      >
        <MenuItem value="Low">Low</MenuItem>
        <MenuItem value="Medium">Medium</MenuItem>
        <MenuItem value="High">High</MenuItem>
      </TextField>
      <TextField
        fullWidth
        label="Due Date"
        name="dueDate"
        type="date"
        value={task.dueDate}
        onChange={handleChange}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      </>
        :''}
      <TextField
        fullWidth
        label="Estimated Time (hours)"
        name="estimatedTime"
        type="string"
        value={task.estimatedTime}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Actual Time (hours)"
        name="actualTime"
        type="number"
        value={task.actualTime}
        onChange={handleChange}
        margin="normal"
      />
    {userrole != 0 ? 
      <>
      <TextField
        select
        fullWidth
        label="Parent Task"
        name="parentTask"
        value={task.parentTask}
        onChange={handleChange}
        margin="normal"
      >
        <MenuItem value="None">None</MenuItem>
        {TasksN.map( (data) =>
          <MenuItem value={data._id}>{data.title}</MenuItem>
        )}
        
      </TextField>
      <TextField
        select
        fullWidth
        label="Realtion with Parent Task"
        name="typeTask"
        value={task.typeTask}
        onChange={handleChange}
        margin="normal"
      >
        <MenuItem value="None">None</MenuItem>
        <MenuItem value="Sub Task">Sub Task</MenuItem>
        <MenuItem value="Parallel">Parallel</MenuItem>
        
        
      </TextField>

      <FormControl fullWidth margin="normal">
        <Stack>
          <Userlist selectedItems={selectedItems} onSelectionChange={handleSelectionChange} />
        </Stack>
      </FormControl>
    </>
    :''}
      <Button type="submit" variant="contained" mb={4} sx={{ mt: 3 }}>
        {initialData ? 'Update Task' : 'Create Task'}
      </Button>
    </Box>
  );
}

export default TaskForm;

import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, List, ListItem, ListItemText, Alert, TextField } from '@mui/material';
import axios from 'axios';

function Documents(props) {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userSearchTerm, setUserSearchTerm] = useState('');
  const [senderSearchTerm, setSenderSearchTerm] = useState('');
  const [fileSearchTerm, setFileSearchTerm] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://ideawork.in/api/projects/mess');
        setUsers(response.data);
        setFilteredUsers(response.data); // Initialize filteredUsers with the full user list
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    // Filter users based on the search terms
    setFilteredUsers(
      users
        .filter(user =>
          user.name.toLowerCase().includes(userSearchTerm.toLowerCase())
        )
        .map(user => ({
          ...user,
          messages: user.messages
            .filter(message =>
              message.sender.toLowerCase().includes(senderSearchTerm.toLowerCase())
            )
            .map(message => ({
              ...message,
              attachment: message.attachment
                ? message.attachment.filter(data =>
                  data.originalName.toLowerCase().includes(fileSearchTerm.toLowerCase())
                )
                : [],
            }))
        }))
        .filter(user => user.messages.some(message => message.attachment.length > 0))
    );
  }, [userSearchTerm, senderSearchTerm, fileSearchTerm, users]);

  const tell = (user) => {
    return user.messages.map((message) => {
      if (message.attachment && message.attachment.length > 0) {
        return (
          <React.Fragment key={message._id || message.timestamp}>
            <ListItem alignItems="flex-start">
              <span style={{ marginRight: '10px' }}><strong>{i++} .</strong></span>
              <ListItemText
                primary={message.text}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {message.sender}
                    </Typography>
                    {" — "}{new Date(message.timestamp).toLocaleString()}
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                      {message.attachment.map((data) => (
                        <Box key={data.path}>
                          <Button
                            variant="outlined"
                            size="small"
                            href={`https://upload.ideawork.in${data.path}`}
                            download={data.originalName}  // Use this to download with the original filename
                            target='_Blank'
                          >
                            {data.originalName}
                          </Button>
                        </Box>
                      ))}
                    </div>
                  </React.Fragment>
                }
              />
            </ListItem>
            <hr style={{ marginLeft: '20px', marginRight: '20px', opacity: '0.4' }}></hr>
          </React.Fragment>
        );
      }
      return null;
    });
  }

  var i = 1;

  return (
    <>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        {props.filterShow == 'dontshow' ?  '' :
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          <div>
            <TextField
              label="Search by Product Name"
              variant="outlined"
              fullWidth
              value={userSearchTerm}
              onChange={(e) => setUserSearchTerm(e.target.value)}
              style={{ marginBottom: '20px' }}
            />
          </div>
          <div>

            <TextField
              label="Search by Message Sender"
              variant="outlined"
              fullWidth
              value={senderSearchTerm}
              onChange={(e) => setSenderSearchTerm(e.target.value)}
              style={{ marginBottom: '20px' }}
            />
          </div>
          <div>
            <TextField
              label="Search by File Name"
              variant="outlined"
              fullWidth
              value={fileSearchTerm}
              onChange={(e) => setFileSearchTerm(e.target.value)}
              style={{ marginBottom: '20px' }}
            />
          </div>
        </div>
        }
        <List>
          {filteredUsers.map((user) => (
            <React.Fragment key={user.name}>
              <Alert icon={false} style={{ textAlign: 'center' }} severity="success">
                {user.name}
              </Alert>
              {tell(user)}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </>
  );
}

export default Documents;
